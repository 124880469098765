/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useState, useContext} from "react"
import PropTypes from "prop-types"
import { Helmet } from 'react-helmet'
import { GlobalStateContext } from "../context/GlobalContextProvider"

import Header from "./header"
import Footer from "./footer"
import LetsTalkModal from "./letstalk"
import "./layout.css"


const Layout = ({ children }) => {
  const [menuActive, setMenuActive] = useState(0)
  const menuClickHandler = () => {
    setMenuActive(!menuActive);
  }

  const state = useContext(GlobalStateContext)
  

  const bodyClass = `is-scroll-top ${menuActive ? 'is-menu-active' : '' } ${state.isModalActive ? 'is-modal-form' : ''}`

  return (
    <>
      <div className="dialog-off-canvas-main-canvas">
        <Helmet bodyAttributes={{ class:  bodyClass }}></Helmet>
        <Header clickHandler={menuClickHandler} />
        <main role="main" className="l-m">
          {children}
        </main>
        <Footer/>
        <LetsTalkModal/>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
