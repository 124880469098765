import React from "react";
import logo from '../images/logo.svg';

const Footer = () => {
  const currentYear = new Date().getFullYear(); // Get the current year

  return (
    <footer className="footer l-f">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <a href="/" title="Yemora" className="l-f__logo h-link">
              <img src={logo} alt="Logo" />
            </a>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8 order-lg-2 offset-lg-1">
            <div className="row">
              <div className="col-6 col-sm-3">
                <span className="l-f__menu-name">
                  <a href="/what-we-do" className="h-link">What we do</a>
                </span>
                <div className="l-f__menu">
                  <a href="/what-we-do/strategy" title="Strategy" className="l-f__menu-item h-link">Strategy</a>
                  <a href="/what-we-do/technology" title="Technology" className="l-f__menu-item h-link">Technology</a>
                  <a href="/what-we-do/transformation" title="Transformation" className="l-f__menu-item h-link">Transformation</a>
                  <a href="/what-we-do/platforms" title="Industries" className="l-f__menu-item h-link">Industries</a>
                </div>
              </div>
              <div className="col-6 col-sm-3">
                <span className="l-f__menu-name">
                  <a href="/what-we-do/platforms" className="h-link">Platforms</a>
                </span>
                <div className="l-f__menu">
                  <a href="https://www.magento.com" title="Magento" className="l-f__menu-item h-link" rel="nofollow">Magento</a>
                  <a href="https://www.salesforce.com" title="Salesforce" className="l-f__menu-item h-link" rel="nofollow">Salesforce</a>
                  <a href="https://www.sap.com" title="SAP" className="l-f__menu-item h-link" rel="nofollow">SAP</a>
                  <a href="https://www.shopify.com" title="Shopify" className="l-f__menu-item h-link" rel="nofollow">Shopify</a>
                  <a href="https://aws.amazon.com" title="AWS" className="l-f__menu-item h-link" rel="nofollow">AWS</a>
                </div>
              </div>
              <div className="col-6 col-sm-3">
                <span className="l-f__menu-name">
                  <a href="/who-we-are" className="h-link">Who we are</a>
                </span>
                <div className="l-f__menu">
                  <a href="/culture" title="Culture" className="l-f__menu-item h-link">Culture</a>
                  <a href="/who-we-are/diversity-and-inclusion" title="Inclusion & diversity" className="l-f__menu-item h-link">Inclusion & diversity</a>
                  <a href="/newsroom" title="Newsroom" className="l-f__menu-item h-link">Newsroom</a>
                </div>
              </div>
              <div className="col-6 col-sm-3">
                <a href="https://www.linkedin.com/jobs/view/2492955123/" target="_blank" rel="noopener noreferrer" title="Careers" className="l-f__menu-item l-f__menu-item--right h-link">Careers</a>
              </div>
            </div>
          </div>
          <div className="col-12 offset-lg-4 col-lg-6 order-lg-3">
            <span className="l-f__line"></span>
          </div>
          <div className="col-12 col-lg-4 order-lg-4">
          </div>
          <div className="col-12 col-lg-3 order-lg-1">
            <div className="l-f__content l-f__content--left">
              <p></p>
            </div>
          </div>
          <div className="col-12 col-lg-2 order-lg-5">
            <div className="l-f__menu">
              <a href="/privacy-policy" title="Privacy policy" className="l-f__menu-item h-link">Privacy policy</a>
              <a href="/terms-of-use" title="Terms of use" className="l-f__menu-item h-link">Terms of use</a>
            </div>
          </div>
          <div className="col-12 col-lg-2 order-lg-7">
            <p className="l-f__quote h-text-medium">Love your future <span className="l-footer_shape"></span></p>
          </div>
          <div className="col-12 col-lg-4 order-lg-6">
            <p className="l-f__content l-f__content--disclaimer"></p>
            <p>© {currentYear} Yemora, LLC.&nbsp;</p>
            <p><br/><br/>All rights reserved</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
