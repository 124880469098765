import React, {useContext} from "react"
import logo from '../images/logo.svg';
import { GlobalDispatchContext } from "../context/GlobalContextProvider"

const Header = ({clickHandler}) => {
	const dispatch = useContext(GlobalDispatchContext)

	return (
		<header id="js-header" className="l-h">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<a href="/" title="Yemora" className="l-h__logo h-link">
							<img src={logo} alt="Logo" />
						</a>
						<nav className="l-h__m">
							<div className="l-h__m-container">
								<div className="l-h__m-content">
									<ul className="l-h__m-list">
										<li className="l-h__m-li l-h__m-li-row-2 l-h__m-li--home">
											<a href="/" target="" title="Home" className="l-h__m-link a-link--wline-span">
											<span className="l-h__m-link-w">Home</span>
											</a>
										</li>
										<li className="l-h__m-li l-h__m-li-row-2 ">
											<a href="/what-we-do" target="" title="What we do" className="l-h__m-link a-link--wline-span">
											<span className="l-h__m-link-w">What we do</span>
											</a>
										</li>
										<li className="l-h__m-li l-h__m-li-row-2 ">
											<a href="/who-we-are" target="" title="Who we are" className="l-h__m-link a-link--wline-span">
											<span className="l-h__m-link-w">Who we are</span>
											</a>
										</li>
										<li className="l-h__m-li l-h__m-li-row-2 ">
											<a href="https://www.linkedin.com/jobs/view/2492955123/" target="_blank" title="Careers" className="l-h__m-link a-link--wline-span">
											<span className="l-h__m-link-w">Careers</span>
											</a>
										</li>
										<li className="l-h__m-li">
											<button
												className="js-modal__bt-open-form js-header__bt l-h__m-link l-h__m-link@sm"
												data-gaeventlabel="Let's talk nav"
												onClick={() => {
													dispatch({ type: "TOGGLE_MODAL" })
												}}
											>
												<span className="l-h__m-link-w cta_button">
													Let's talk
												</span>
											</button>
										</li>
										<li className="l-h__m-li">
											<button aria-label="Search" className="js-modal__bt-open-search js-header__bt l-h__m-link l-h__m-search l-h__m-link-w">
											</button>
										</li>
										{/* <li className="l-h__m-li l-h__m-lang-switcher">
											<button id="language-picker__container" className="l-h__m-link">
												<div>
													<div className="language-switcher-language-url" id="block-languageswitcher" role="navigation">
														<div id="language-picker">
															<div id="language-picker__current" className="l-h__m-lang-switcher-current">
																<p>
																	Srpski
																	<span className="language-picker__icon">
																		
																	</span>
																</p>
															</div>
															<ul id="language-picker__options" className="l-h__m-lang-options">
																<li hrefLang="fr-ca" className="fr-ca">
																	<a href="/fr-ca/secteurs-dactivite/?lp=1" className="language-link" hrefLang="fr-ca" data-drupal-link-query="{&quot;lp&quot;:1}">Français</a>
																</li>
															</ul>
														</div>
													</div>
												</div>
											</button>
										</li> */}
										<hr aria-hidden="true" className="l-h__m-li-hr"/>
									</ul>
								</div>
							</div>
						</nav>
						<span id="js-header__bt" className="l-h__bt-m" role="button" onClick={clickHandler}>
							<span className="h-abs-center"></span>
							<span className="h-abs-center"></span>
							<span className="h-abs-center"></span>
						</span>
					</div>
				</div>
			</div>
		</header>
	)
}
	
export default Header
