import React, {useContext} from "react"
import logo from '../images/logo-black.svg';
import { GlobalDispatchContext } from "../context/GlobalContextProvider"

const LetsTalkModal = () => {
    const dispatch = useContext(GlobalDispatchContext)

    return (
        <div id="js-modal" className="l-modal">
            <div 
                tabIndex="0"
                className="l-modal__bg js-modal__bt-close"
                role="button"
                onClick={() => {
                    dispatch({ type: "TOGGLE_MODAL" })
                }}
            >
            </div>
            <div id="js-modal__content-form" className="l-modal__content l-modal__content--form" data-action="/slalom/contact" data-tp="/success">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <a href="/" title="Slalom" className="l-modal__content--form__logo">
                                <img src={logo} alt="Logo" />
                            </a>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-5">
                            <header className="l-modal__content--form__header">
                                <div className="l-modal__content--form__content">
                                    <h3 className="a-title-60 h-text-light">
                                        Let's talk about your next big project.
                                    </h3>
                                </div>
                            </header>
                        </div>
                        <div className="col-12 col-lg-6 offset-xl-1">
                            <div className="s-form">
                                <form name="lets-talk" method="POST" data-netlify="true">
                                    <fieldset>
                                        <div>
                                            <label htmlFor="form-1-full-name" className="required">Name</label>
                                            <input type="text" placeholder="Full name" id="form-1-full-name" name="full_name" required="required" data-validation-message="Please enter full name" tabIndex="0"/>
                                            <div className="form-error"></div>
                                        </div>
                                        <div>
                                            <label htmlFor="form-1-email" className="required">Email</label>
                                            <input type="email" placeholder="name@company.com" id="form-1-email" name="email" required="required" data-validation-message="Please enter valid email" tabIndex="0"/>
                                            <div className="form-error"></div>
                                        </div>
                                        <div>
                                            <label htmlFor="form-1-phone">Phone</label>
                                            <input type="tel" placeholder="(+55) 555-555-5555" id="form-1-phone" name="phone" data-validation-message="Please enter valid phone number" tabIndex="0"/>
                                            <div className="form-error"></div>
                                        </div>
                                        <div>
                                            <label htmlFor="form-1-company">Company</label>
                                            <input type="text" placeholder="Company name" id="form-1-company" name="company" data-validation-message="Please enter valid company" pattern=".+" tabIndex="0"/>
                                            <div className="form-error"></div>
                                        </div>
                                        <div>
                                            <label htmlFor="form-1-job-title">Job Title</label>
                                            <input type="text" placeholder="IT Manager" id="form-1-job-title" name="job_title" data-validation-message="Please enter valid job title" pattern=".+" tabIndex="0"/>
                                            <div className="form-error"></div>
                                        </div>
                                        <div>
                                            <label htmlFor="form-1-message" className="required">Message:</label>
                                            <textarea id="form-1-message" name="message" placeholder="Add details about your project..." tabIndex="0"></textarea>
                                            <div className="form-error"></div>
                                        </div>
                                        <button type="submit" tabIndex="0">
                                            Submit
                                            <span className="a-spinner h-abs-center"></span>
                                        </button>
                                        <input type="hidden" name="form-name" value="lets-talk" />
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="l-modal__content l-modal__content--video h-abs-center">
                <div id="js-modal__content-video" className="l-modal__content-iframe">
                    <span className="a-spinner h-abs-center"></span>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div
                            tabIndex="0"
                            className="l-modal__bt-close js-modal__bt-close"
                            role="button"
                            onClick={() => {
                                dispatch({ type: "TOGGLE_MODAL" })
                            }}
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
	
export default LetsTalkModal
